import apiClient from "../../axiosInstance";

// GET request for filters
export const fetchFilters = async () => {
  const response = await apiClient.get('/api/filters');
  return response.data.data;
};

// POST request for updated driver based on lever
export const fetchUpdatedDriver = async (filters) => {
  const response = await apiClient.post('/api/filters/new', { filters });
  return response.data.data;
};

// POST request for categories based on sector
export const fetchUpdatedCategory = async (sector) => {
  const response = await apiClient.post('/api/opportunity/filter-category-by-sector', { sector });
  return response.data.data;
};

// POST request to get initiative details
export const fetchInitiativeDetails = async (id, year) => {
  const response = await apiClient.post('/api/initiative/get-details', { src_id: id, cal_year: year });
  return response.data.data;
};

export const createInitativeService = async (data) => {
  const response = await apiClient.post('/api/initiative/create', data);
  return response.data.data;
}

export const deleteInitiativeService = async (data) => {
  const response = await apiClient.post('/api/initiative/delete', data);
  return response.data.data;
}