export const COMPLEIXTIES = ["Low", "Medium", "High"];

export const CLASSIFICATIONS = [
  { value: "null", label: "N/A" },
  { value: "Duplicate", label: "Duplicate" },
];

export const REPORT_OPTIONS = ["No", "Yes"];

export const STATUS_OPTIONS = [
  { value: "NOT_STARTED", color: "darkgray", label: "Not Started" },
  { value: "ON_TRACK", color: "green", label: "On Track" },
  { value: "OFF_TRACK", color: "red", label: "Off Track" },
  { value: "COMPLETED", color: "gray", label: "Completed" },
  { value: "CANCELLED", color: "blue", label: "Cancelled" },
];
