import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { Button, Grid } from "@mui/material";
import DownloadExcel from "./DownloadExcel";
import { CircularProgress } from "@mui/material";
import CustomAlert from "../../components/CustomAlert";

import { useHistory } from "react-router-dom";
function BulkUpload() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("");
  const idToken = localStorage.getItem("token");
  const history = useHistory();
  const requiredHeaders = [
    "Opportunity Name",
    "Sector",
    "Lever",
    "Classification",
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const validateHeaders = async (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        // Read the file data
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Get the first sheet
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        // Get the headers (first row)
        const headers = XLSX.utils.sheet_to_json(firstSheet, { header: 1 })[0];

        // Check for missing headers
        const missingHeaders = requiredHeaders.filter(
          (header) => !headers.includes(header)
        );

        if (missingHeaders.length > 0) {
          reject(`Missing headers: ${missingHeaders.join(", ")}`);
        } else {
          resolve();
        }
      };

      reader.onerror = () => {
        reject("Failed to read the file.");
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }
    // try {
    //   await validateHeaders(file);
    // } catch (error) {
    //   alert(`Validation error: ${error}`);
    //   return;
    // }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const uploadUrl = `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/upload-file`;
    axios
      .post(uploadUrl, formData, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        console.log("Response:", response.data);
        setAlertMessage("Opportunity's Uploaded Successfully");
        setAlertType("success");
        setAlertVisible(true);
        console.log(history)
        setTimeout(() => {
          history.push("/");
        }, 2000);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.log(error)
        const errMessage = error?.response?.data?.message || 'Something went wrong';
        setAlertMessage(errMessage);
        setAlertType("error");
        setAlertVisible(true);
        // setFile(null)
      })
      .finally(() => {
        setLoading(false); // Stop loader
      });
  };

  return (
    <>
      <div className="sub-heading" style={{ margin: "20px 0px 25px 0px" }}>
        Add Opportunities in Bulk
      </div>
      <Grid container className="grid-container-upload">
        <Grid>
          <div className="file-input-wrapper">
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
            />
          </div>
          <div
            style={{
              fontSize: "10px",
              margin: "5px 2px",
            }}
            className="download-opportunity"
          >
            Required format: .xlsx
          </div>
        </Grid>
        <Grid className="download-sample">
          <DownloadExcel />
        </Grid>
        <Grid style={{ position: "absolute", right: "30px" }}>
          <Button
            color="primary"
            variant="contained"
            className={!file ? "custom-upload-disabled" : "custom-upload"}
            onClick={handleUpload}
            disabled={!file}
          >
            {loading ? (
              <div>
                <CircularProgress size={20} />
                <span style={{ marginLeft: "20px" }}>Uploading...</span>
              </div>
            ) : (
              "Upload File"
            )}
          </Button>
        </Grid>
      </Grid>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </>
  );
}

export default BulkUpload;
