import { CONSTANTS } from "../../data/constants";

const currentYear = CONSTANTS?.currentYear;

export const tableStyles = {
    "& .MuiDataGrid-cell": {
      justifyContent: "center",
      // textAlign: 'center',
    },
    "& .MuiDataGrid-columnHeader": {
      justifyContent: "center",
      padding: 0,
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      background: "#fff",
      border: "none",
      borderRadius: "10px",
    },
    "& .MuiDataGrid-container--top::after": {
      content: "none",
      display: "none",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      boxShadow: "0px 4px 16px 0px #3241FF29",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      display: "flex",
      justifyContent: "center",
      borderRadius: "15px 15px 0 0",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "none",
      background: "none",
      fontSize: "14px",
      fontWeight: 500,
      color: "#334D74",
    },
    '& .MuiDataGrid-columnHeader[data-field="Total"] .MuiDataGrid-columnHeaderTitleContainer':
      {
        backgroundColor: "#FFFFFF",
      },
    [`& .MuiDataGrid-columnHeader[data-field="${currentYear}"] .MuiDataGrid-columnHeaderTitleContainer`]:
      {
        backgroundColor: "#3FBAD91A",
      },
    [`& .MuiDataGrid-columnHeader[data-field="${
      currentYear + 1
    }"] .MuiDataGrid-columnHeaderTitleContainer`]: {
      backgroundColor: "#E4D4C799",
    },
    [`& .MuiDataGrid-columnHeader[data-field="${
      currentYear + 2
    }"] .MuiDataGrid-columnHeaderTitleContainer`]: {
      backgroundColor: "#5DB1341A",
    },
  };

  export const customMenuProps = {
    PaperProps: {
      sx: {
        marginTop: "5px",
        // left: "1070px !important",
        border: "1px solid #BBC9FB",
      },
      className: "css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper",
    },
  };

  export const selectStyle = {
    fontFamily: "Poppins, sans-serif !important",
    background: "#FFF",
    border: "1px solid #BBC9FB",
    borderRadius: "5px",
    height: "50px",
    paddingLeft: "8px",
    boxSizing: "border-box",
    width: "100%",
    "&.Mui-focused": {
      border: "2px solid #6b81d0", 
    },
  };

  export const rowStyle = {
    fontFamily: "Poppins, sans-serif !important",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "16px",
    marginBottom: "16px",
  };

  export const columnStyle = {
    fontFamily: "Poppins, sans-serif !important",
    flex: "1 1 22%",
    marginRight: "30px",
  };

  export const inputContainerStyle = {
    fontFamily: "Poppins, sans-serif !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
  };

  export const InputPropsStyle = {
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  export const InputCategoryStyle = {
    // flex: "0 1 70%",
    marginLeft: "10px",
    height: 50,
    outline: "none",
    width: "75%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  export const InputCategoryStyleTotal = {
    flex: "0 1 80%",
    margin: "20px 0px 0px 12px",
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "14px",
  };

  export const InputCategoryPerStyle = {
    flex: "0 1 25%",
    marginLeft: "10px",
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  export const InputCategoryPerStylePer = {
    // flex: "0 1 15%",
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "14px",
    border:'none',
    width:'18%'
    // margin: "20px 0px 0px 0px",
  };
  export const commentPropStyle = {
    height: 50,
    outline: "none",
    border:'none',
    width: "149%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  export const loadingScreenStyle = {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#ffffff90",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 99,
  }

  export const descriptionInputStyle = {
    height: 50,
    outline: "none",
    width: "103%",
    boxSizing: "border-box",
    background: "white",
  }

  export const actionButtonWrapperStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginTop: 2,
  }

  export const saveButtonStyle = (hasAccess) => ({
    fontSize: "15px",
    fontWeight: 500,
    color: "#fff",
    width: "164px",
    height: "42px",
    background: hasAccess === "true" ? "#004DCD" : "#ccc",
    border: "none",
    borderRadius: '10px',
    cursor: hasAccess === "true" ? "pointer" : "not-allowed",
    fontFamily: "Poppins,sans-serif !important",
  })

  export const cancelButtonStyle = {
    fontSize: "15px",
    fontWeight: 500,
    width: "164px",
    height: "42px",
    background: "#fff",
    border: "1px solid black",
    borderRadius: 10,
    marginLeft: 35,
    cursor: "pointer",
    fontFamily: "Poppins,sans-serif !important",
  }

  export const deleteButtonStyle = hasAccess => ({
    fontSize: "15px",
    fontWeight: 500,
    color: "#fff",
    width: "164px",
    height: "42px",
    background: hasAccess === "true" ? "red" : "#ccc",
    border: "none",
    borderRadius: 10,
    cursor: hasAccess === "true" ? "pointer" : "not-allowed",
    marginLeft: 35,
    fontFamily: "Poppins,sans-serif !important",
  })