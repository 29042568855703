import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CircularProgress } from "@mui/material";
import CustomAlert from "../../components/CustomAlert";

function DownloadExcel() {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("");

  const downloadTableData = async () => {
    setLoadingDownload(true);
    let apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/download-sample`;
    try {
      const idToken = localStorage.getItem("token");
      const response = await axios.get(apiURL, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      });
      const data = await response.data.data;
      const filename = `Opportunity's_Sample`;
      console.log("Response download", data);
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate a binary Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `${filename}.xlsx`);
      setLoadingDownload(false);
      console.log("downloaded")
      setAlertMessage("Template downloaded Successfully");
      setAlertType("success");
      setAlertVisible(true);
    } catch (error) {
      setLoadingDownload(false);
      console.error("Error while fetching PBI token...", error);
      setAlertMessage(error?.message);
      setAlertType("error");
      setAlertVisible(true);
    }
  };
  return (
    <>
    <button className="download-sample" onClick={downloadTableData}>
      Download a Template to see an example of required format. {" "}{loadingDownload && <CircularProgress size={20} />}
    </button>
    <CustomAlert
    message={alertMessage}
    type={alertType}
    visible={alertVisible}
    onClose={() => setAlertVisible(false)}
  />
  </>
  );
}

export default DownloadExcel;
