import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogTitle,
  CircularProgress,
  Breadcrumbs,
  Typography,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ReactComponent as EditNoteIcon } from "../../assets/images/Edit.svg";
import CustomAlert from "../../components/CustomAlert";
import { DateRangePicker } from "rsuite";
import moment from "moment-timezone";
import {
  COMPLEIXTIES,
  CLASSIFICATIONS,
  REPORT_OPTIONS as VALID_OPTIONS,
  STATUS_OPTIONS,
} from "../initiative/Constants.js";
import { CONSTANTS } from "../../data/constants";
import "rsuite/dist/rsuite.min.css";
import "../../styles/opportunity.scss";
import BulkUpload from "../opportunity/BulkUpload";
import axios from "axios";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useCallback } from "react";
import {
  cancelButtonStyle,
  saveButtonStyle,
  actionButtonWrapperStyle,
  columnStyle,
  commentPropStyle,
  customMenuProps,
  deleteButtonStyle,
  descriptionInputStyle,
  InputCategoryPerStylePer,
  InputCategoryStyle,
  InputCategoryStyleTotal,
  inputContainerStyle,
  InputPropsStyle,
  loadingScreenStyle,
  rowStyle,
  selectStyle,
  tableStyles,
} from "./styles.js";
import {
  createInitativeService,
  deleteInitiativeService,
  fetchFilters,
  fetchUpdatedCategory,
  fetchUpdatedDriver,
} from "./services.js";

export default function CreateInitiative() {
  const { id, year } = useParams();
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const isInitialRender = useRef(true);
  const [loading, setLoading] = useState(true);
  const [saveOpen, setSaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [sectorFromEdit, setSectorFromEdit] = useState("new");
  const [calendarDate, setCalendarDate] = useState([new Date(), new Date()]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lever, setLever] = useState("");
  const [driver, setDriver] = useState("");
  const [sector, setSector] = useState("");
  const [timeline, setTimeline] = useState([null, null]);
  const [owner, setOwner] = useState("");
  const [secondOwner, setSecondOwner] = useState("");
  const [priority, setPriority] = useState("");
  const [classification, setClassification] = useState("");
  const [complexity, setComplexity] = useState("");
  const [isValid, setIsValid] = useState("Yes");
  const [comments, setComments] = useState("");
  const [categories, setCategories] = useState([]);
  const [tempCategories, setTempCategories] = useState([]);
  const [country, setCountry] = useState("Peru");
  const [status, setStatus] = useState("NOT_STARTED");
  const [financialOwner, setFinancialOwner] = useState("");
  const [risks, setRisks] = useState("");
  const [countryList] = useState(["Peru"]);

  const [categoryAllocation, setCategoryAllocation] = useState([
    { category: "", allocation: "" },
  ]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sectorList, setSectorList] = useState([]);
  const [leverList, setLeverList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteText, setDeletetext] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [clientTimeZone, setClientTimeZone] = useState("UTC");
  const isEditPath = path.includes("edit");
  const hasAccess = localStorage.getItem("isUserAccess");
  const claims = localStorage.getItem("claims");
  const currentYear = CONSTANTS?.currentYear;
  const [impactRows, setImpactRows] = useState([
    {
      id: 1,
      metric: "Inc NSV",
      Total: 0,
      [currentYear]: 0,
      [currentYear + 1]: 0,
      [currentYear + 2]: 0,
      editable: true,
      field: "nsv",
    },
    {
      id: 2,
      metric: "Inc GP($)",
      Total: 0,
      [currentYear]: 0,
      [currentYear + 1]: 0,
      [currentYear + 2]: 0,
      editable: true,
      field: "gp",
    },
  ]);
  const [initiativeDetailsResp, setInitiativeDetailsResp] = useState([]);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleClickOpen = () => {
    setSaveOpen(true);
  };

  const handleClose = () => {
    setSaveOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const roles = claims?.groups || [];
  const isRGMUser = roles.includes(process.env.REACT_APP_RGM_USER);

  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      Initiative
    </Typography>,
  ];

  useEffect(() => {
    setTempCategories([...categories]);
  }, []);

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setClientTimeZone(timeZone);
  }, []);

  const setFixedTimeInTimeZone = (dateString) => {
    if (!dateString) return null;
    const date = moment.tz(dateString, clientTimeZone);
    return date.set({ hour: 14, minute: 0, second: 0 }).toDate();
  };

  const fetchFiltersList = useCallback(async () => {
    try {
      const data = await fetchFilters();
      setSectorList(data.sector);
      setLeverList(data.lever);
      setDriverList(data.driver);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchUpdatedDriverList = useCallback(async (lever) => {
    if (!lever) return;
    try {
      const filters = { sector: [], lever: [lever] };
      setDriverList([]);
      const data = await fetchUpdatedDriver(filters);
      setDriverList(data.driver);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchUpdatedCategoryList = useCallback(
    async (sector, sectorFromEdit, categoryAllocation) => {
      if (!sector) return;
      try {
        setCategories([]);
        const data = await fetchUpdatedCategory(sector);
        if (sectorFromEdit === sector) {
          setCategories(categoryAllocation);
        } else {
          setCategories(data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const clearFormData = useCallback(() => {
    setLoading(false);
    setName("");
    setDescription("");
    setLever("");
    setDriver("");
    setSector("");
    setTimeline([null, null]);
    setOwner("");
    setSecondOwner("");
    setFinancialOwner("");
    setStatus("NOT_STARTED");
    setClassification("");
    setComplexity("");
    setIsValid("Yes");
    setComments("");
    setRisks("");
    const impact = {
      [`impact_gp_${currentYear}`]: 0,
      [`impact_gp_${currentYear + 1}`]: 0,
      [`impact_gp_${currentYear + 2}`]: 0,
      [`impact_nsv_${currentYear}`]: 0,
      [`impact_nsv_${currentYear + 1}`]: 0,
      [`impact_nsv_${currentYear + 2}`]: 0,
    };
    setInitiativeDetailsResp(impact);
  }, [currentYear]);

  useEffect(() => {
    clearFormData();
    fetchFiltersList();
  }, [clearFormData, fetchFiltersList]);

  useEffect(() => {
    if (lever) {
      fetchUpdatedDriverList(lever);
    }
  }, [lever, fetchUpdatedDriverList]);

  useEffect(() => {
    fetchUpdatedCategoryList(sector, sectorFromEdit, categoryAllocation);
  }, [sector, sectorFromEdit, categoryAllocation, fetchUpdatedCategoryList]);

  const columns = useMemo(
    () => [
      {
        field: "metric",
        headerName: "",
        flex: 1,
        align: "center",
        renderCell: (params) => (
          <div
            style={{
              fontWeight: 600,
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: "Total",
        headerName: "Total",
        flex: 1,
        align: "center",
        type: "number",
        renderCell: (params) => (
          <div
            style={{
              fontWeight: 600,
            }}
          >
            {params.value &&
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(parseFloat(params.value))}
          </div>
        ),
      },
      {
        field: `${currentYear}`,
        headerName: `${currentYear}`,
        flex: 2,
        align: "center",
        type: "number",
        editable: true,
        renderCell: (params) => (
          <div>
            {params.value !== null && params.value !== undefined && (
              <div style={{ marginLeft: 30 }}>
                {Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(params.value)}
                <span>
                  <IconButton
                    onClick={() =>
                      params.api.startCellEditMode({
                        id: params.id,
                        field: `${currentYear}`,
                      })
                    }
                  >
                    <EditNoteIcon />
                  </IconButton>{" "}
                </span>
              </div>
            )}
          </div>
        ),
      },
      {
        field: `${currentYear + 1}`,
        headerName: `${currentYear + 1}`,
        flex: 2,
        align: "center",
        type: "number",
        editable: true,
        renderCell: (params) => (
          <div>
            {params.value !== null && params.value !== undefined && (
              <div style={{ marginLeft: 30 }}>
                {Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(params.value)}
                {params?.row.editable && (
                  <span>
                    <IconButton
                      onClick={() =>
                        params.api.startCellEditMode({
                          id: params.id,
                          field: `${currentYear + 1}`,
                        })
                      }
                    >
                      <EditNoteIcon />
                    </IconButton>{" "}
                  </span>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        field: `${currentYear + 2}`,
        headerName: `${currentYear + 2}`,
        flex: 2,
        align: "center",
        type: "number",
        editable: true,
        renderCell: (params) => (
          <div>
            {params.value !== null && params.value !== undefined && (
              <div style={{ marginLeft: 30 }}>
                {Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(params.value)}
                {params?.row.editable && (
                  <span>
                    <IconButton
                      onClick={() =>
                        params.api.startCellEditMode({
                          id: params.id,
                          field: `${currentYear + 2}`,
                        })
                      }
                    >
                      <EditNoteIcon />
                    </IconButton>{" "}
                  </span>
                )}
              </div>
            )}
          </div>
        ),
      },
    ],
    [currentYear]
  );

  const compareObject = (obj1, obj2, commonFieldName) => {
    const result = {};
    if (
      obj1[commonFieldName] &&
      obj2[commonFieldName] &&
      obj1[commonFieldName] === obj2[commonFieldName]
    ) {
      result[commonFieldName] = obj1[commonFieldName];
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && key !== commonFieldName) {
        if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          result[`${commonFieldName}_${key}`] = obj2[key];
        }
      }
    }
    return result;
  };

  const processRowUpdate = (newRow, oldRow) => {
    console.log("NEW Row :::", newRow);
    const updatedRows = impactRows.map((row) => {
      if (row.id === newRow.id) {
        const updated2024 =
          newRow?.[`${currentYear}`] === null
            ? 0
            : parseFloat(newRow?.[`${currentYear}`]);
        const updated2025 =
          newRow?.[`${currentYear + 1}`] === null
            ? 0
            : parseFloat(newRow?.[`${currentYear + 1}`]);
        const updated2026 =
          newRow?.[`${currentYear + 2}`] === null
            ? 0
            : parseFloat(newRow?.[`${currentYear + 2}`]);
        let updateTotal = updated2024 + updated2025 + updated2026;
        let updateRow = { ...newRow, Total: updateTotal };
        newRow = updateRow;
        console.log("NEW UPDATED ROW ::", updateRow);
        return updateRow;
      } else {
        return row;
      }
    });
    setImpactRows(updatedRows);

    const updatedObj = compareObject(oldRow, newRow, newRow.field);
    const editedImpactValueObj = {
      ...initiativeDetailsResp,
      ...updatedObj,
    };

    console.log("Updated Values :::", editedImpactValueObj);

    setInitiativeDetailsResp(editedImpactValueObj);
    return newRow;
  };

  const handleCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const handleIsCellEditable = (params) => {
    return params.colDef.editable && params.row.editable;
  };

  const totalAllocation = categories.reduce((total, category) => {
    const allocation = parseFloat(category.allocation);
    return total + (isNaN(allocation) ? 0 : allocation);
  }, 0);

  const validateForm = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = "Initiative name is required";
    } else if (name?.length > 200) {
      newErrors.name = "Initiative name does not exceed 200 charactors";
    }
    if (description?.length > 300) {
      newErrors.description = "Description does not exceed 300 charactors";
    }
    if (!lever) {
      newErrors.lever = "Lever is required";
    }
    if (!driver) {
      newErrors.driver = "Driver is required";
    }
    if (!sector) {
      newErrors.sector = "Sector is required";
    }
    if (totalAllocation !== 0 && totalAllocation !== 100) {
      newErrors.total = "Total Allocation should be 100%";
    }
    if (owner?.length > 100) {
      newErrors.owner = "Owner does not exceed 100 charactors";
    }
    if (secondOwner?.length > 100) {
      newErrors.secondOwner = "Second Owner does not exceed 100 charactors";
    }
    if (comments?.length > 500) {
      newErrors.comments = "Comments does not exceed 500 charactors";
    }
    if (timeline.length > 1 && timeline[0] === null) {
      newErrors.timeline = "Start Date and End Date are Required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  const handleBlur = (e) => {
    validateForm();
  };

  const handleLeverChange = (e) => {
    setLever(e.target.value);
    setDriver("");
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      validateForm();
    }
  }, [lever, sector, driver]);

  function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const createInitative = async (e) => {
    setLoading(true);
    e.preventDefault();
    handleClose();

    if (!validateForm()) {
      setAlertMessage("Please fill all the mandatory fields!");
      setAlertType("error");
      setAlertVisible(true);
      setLoading(false);
      return;
    }

    try {
      const impacts = impactRows.reduce((acc, row) => {
        if (row.field === "nsv") {
          acc[`impact_nsv_${currentYear}`] = row[`${currentYear}`];
          acc[`impact_nsv_${currentYear + 1}`] = row[`${currentYear + 1}`];
          acc[`impact_nsv_${currentYear + 2}`] = row[`${currentYear + 2}`];
        } else if (row.field === "gp") {
          acc[`impact_gp_${currentYear}`] = row[`${currentYear}`];
          acc[`impact_gp_${currentYear + 1}`] = row[`${currentYear + 1}`];
          acc[`impact_gp_${currentYear + 2}`] = row[`${currentYear + 2}`];
        }
        return acc;
      }, {});

      const payload = {
        ...impacts,
        name,
        description,
        sector: sector,
        lever: lever,
        driver: driver,
        category_allocation: categories || [],
        owner: owner,
        delegate: secondOwner,
        classification: classification,
        complexity,
        is_valid: isValid,
        comments: comments,
        start_date: timeline[0] === null ? "" : formatDateToYMD(timeline[0]),
        end_date: timeline[0] === null ? "" : formatDateToYMD(timeline[1]),
        status,
        financial_owner: financialOwner,
        risks,
        market: country,
      };
      console.log("payload", payload);
      const resp = await createInitativeService(payload);

      setAlertMessage("Initiative saved Successfully");
      setAlertType("success");
      setAlertVisible(true);
      clearFormData();
      setTimeout(() => {
        history.push("/");
      }, 2000);
      window.scrollTo(0, 0);
    } catch (error) {
      setAlertMessage(error?.message);
      setAlertType("error");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteInitiative = async (e) => {
    handleDeleteClose();
    try {
      const payload = {
        src_id: id,
        user: claims?.name,
        change_reason: deleteText,
      };
      const resp = await deleteInitiativeService(payload);

      setAlertMessage("Initiative deleted Successfully");
      setAlertType("success");
      setAlertVisible(true);
      setTimeout(() => {
        history.push("/");
      }, 2000);
      console.log("deleting initiative:::", resp);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error?.message, "error?.message");
      setAlertMessage(error?.message);
      setAlertType("error");
      setAlertVisible(true);
    }
  };

  const handleDateSelect = (value) => {
    setTimeline(value);
    setErrors((prev) => ({ ...prev, timeline: "" }));
    if (value[0] && value[1]) {
      setCalendarDate([value[0], value[0]]);
    }
  };

  const handleCategoryChange = (index, event) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, category: event.target.value } : category
      )
    );
  };

  const handlePercentageChange = (index, event) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, allocation: event.target.value } : category
      )
    );
  };

  return (
    <>
      <div style={{ margin: "30px", position: "relative" }}>
        {loading && (
          <div style={loadingScreenStyle}>
            <CircularProgress size={100} />
          </div>
        )}
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginTop: "10px", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div style={{ marginTop: "10px", borderBottom: "2px solid #BFBFBF" }} />
        <div className="heading" style={{ marginTop: "10px" }}>
          {isEditPath ? "Edit Initiative" : "Create Initiative"}
        </div>
        {/* {!isEditPath && (
          <>
            <Divider />
            <BulkUpload />
            <Divider>OR</Divider>
          </>
        )} */}

        <div className="sub-heading">Initiative details</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Initiative ID</label>
              <input
                type="text"
                disabled
                value={id ? id : "Initiative ID"}
                className="input-style"
              />
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Initiative Name</label>
              <TextField
                value={name}
                placeholder="Initiative Name"
                onChange={(e) => setName(e.target.value)}
                onBlur={handleBlur}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.name && (
                <span style={{ color: "red" }}>{errors.name}</span>
              )}
            </div>
          </div>
          <div style={{ ...columnStyle, flex: "2 2 45%" }}>
            <div
              className="init-details-form"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "206%",
              }}
            >
              <label className="label-type">Description</label>
              <TextField
                value={description}
                placeholder="Add description..."
                onChange={(e) => setDescription(e.target.value)}
                InputProps={{
                  sx: descriptionInputStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.description && (
                <span style={{ color: "red" }}>{errors.description}</span>
              )}
            </div>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Country</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="Country"
                  id="country"
                  variant="standard"
                  value={country}
                  onChange={handleCountryChange}
                  sx={selectStyle}
                  displayEmpty
                  disabled
                  // renderValue={(selected) => {
                  //   if (!selected) {
                  //     return <em>Select</em>;
                  //   }
                  //   return selected;
                  // }}
                >
                  {countryList.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.country && (
                <span style={{ color: "red" }}>{errors.country}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Lever</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="lever"
                  id="lever"
                  variant="standard"
                  value={lever}
                  onChange={handleLeverChange}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {/* {leverList.map((lever) => (
                    <MenuItem key={lever} value={lever}>
                      {lever}
                    </MenuItem>
                  ))} */}
                  <MenuItem value="RGM" selected={true}>
                    RGM
                  </MenuItem>
                </Select>
              </FormControl>
              {errors.lever && (
                <span style={{ color: "red" }}>{errors.lever}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Driver</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="driver"
                  id="driver"
                  variant="standard"
                  value={driver}
                  onChange={(e) => setDriver(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {driverList.length > 0 ? (
                    driverList.map((driver) => (
                      <MenuItem key={driver} value={driver}>
                        {driver}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>Loading...</MenuItem>
                  )}
                </Select>
              </FormControl>
              {errors.driver && (
                <span style={{ color: "red" }}>{errors.driver}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Sector</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="sector"
                  id="sector"
                  variant="standard"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {sectorList.map((sector) => (
                    <MenuItem key={sector} value={sector}>
                      {sector}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.sector && (
                <span style={{ color: "red" }}>{errors.sector}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Category</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                }}
                size="small"
              >
                <Select
                  id="category"
                  variant="standard"
                  sx={selectStyle}
                  displayEmpty
                  MenuProps={customMenuProps}
                  open={isDropdownOpen}
                  onOpen={() => setIsDropdownOpen(true)}
                  onClose={() => setIsDropdownOpen(false)}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <em>
                          {totalAllocation > 0
                            ? categories[0].category
                            : "Select"}
                        </em>
                      );
                    }
                    return selected;
                  }}
                >
                  {categories?.map((category, index) => (
                    <div key={index} className="category-input">
                      <TextField
                        disabled
                        placeholder="Enter category name"
                        value={category.category}
                        onChange={(e) => handleCategoryChange(index, e)}
                        style={InputCategoryStyle}
                        InputProps={{
                          style: { fontSize: "14px" },
                        }}
                      />
                      <TextField
                        value={category.allocation}
                        onBlur={handleBlur}
                        placeholder="0"
                        onChange={(e) => handlePercentageChange(index, e)}
                        style={InputCategoryPerStylePer}
                        InputProps={{
                          style: { fontSize: "14px" },
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ))}
                  {categories.length > 0 && (
                    <div
                      className="category-input"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={InputCategoryStyleTotal}>Total</div>
                      <div style={InputCategoryPerStylePer}>
                        {totalAllocation} %
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  ></div>
                </Select>
              </FormControl>
              {errors.total && (
                <span style={{ color: "red" }}>{errors.total}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Timeline</label>

              <DateRangePicker
                onBlur={handleBlur}
                value={timeline}
                format="dd/MM/yyyy"
                height="50px"
                sx={{
                  border: "1px solid #BBC9FB",
                  width: "900px",
                  "&:focus": { border: "2px solid #6b81d0" },
                }}
                onChange={handleDateSelect}
                showOneCalendar
                cleanable={false}
              />
              {errors.timeline && (
                <span style={{ color: "red" }}>{errors.timeline}</span>
              )}
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="owner name"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.owner && (
                <span style={{ color: "red" }}>{errors.owner}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Second Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="owner name"
                value={secondOwner}
                onChange={(e) => setSecondOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.secondOwner && (
                <span style={{ color: "red" }}>{errors.secondOwner}</span>
              )}
            </div>
          </div>
        </div>
        <div className="sub-heading">Potential Impact</div>
        <DataGrid
          rows={impactRows}
          columns={columns}
          pageSize={5}
          columnHeaderHeight={30}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          autoHeight
          hideFooter
          hideFooterSelectedRowCount
          disableColumnFilter
          disableColumnResize
          disableColumnSorting
          disableColumnMenu
          isCellEditable={handleIsCellEditable}
          onCellDoubleClick={handleCellDoubleClick}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          sx={tableStyles}
        />
        <div className="sub-heading">More details</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Financial Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="Financial Owner name"
                value={financialOwner}
                onChange={(e) => setFinancialOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.fOwner && (
                <span style={{ color: "red" }}>{errors.fOwner}</span>
              )}
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Complexity</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="complexity"
                  id="complexity"
                  variant="standard"
                  value={complexity}
                  onChange={(e) => setComplexity(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {COMPLEIXTIES.map((difficulty) => (
                    <MenuItem key={difficulty} value={difficulty}>
                      {difficulty}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Status</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="status"
                  id="status"
                  variant="standard"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  sx={selectStyle}
                  // displayEmpty
                  MenuProps={{
                    PopoverClasses: {
                      paper: "custom-select-popover-init-details",
                    },
                  }}
                  // renderValue={(selected) => {
                  //   if (!selected) {
                  //     return <em>Select</em>;
                  //   }
                  //   return selected;
                  // }}
                >
                  {STATUS_OPTIONS.map((status) => (
                    <MenuItem
                      className="init-details-menu"
                      key={status.value}
                      value={status.value}
                    >
                      <FiberManualRecordIcon
                        sx={{ padding: "2px", color: status.color }}
                      />
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Classification</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="classification"
                  id="classification"
                  variant="standard"
                  value={classification}
                  onChange={(e) => setClassification(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {CLASSIFICATIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Is Valid</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                  height: "50px",
                }}
                size="small"
              >
                <Select
                  labelId="is-valid"
                  id="is-valid"
                  variant="standard"
                  value={isValid}
                  onChange={(e) => setIsValid(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {VALID_OPTIONS.map((report) => (
                    <MenuItem key={report} value={report}>
                      {report}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ ...columnStyle, flex: "2 2 45%" }}>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            <label className="label-type">Comments</label>
            <TextField
              onBlur={handleBlur}
              id="comments"
              placeholder="Add comments..."
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              InputProps={{
                sx: commentPropStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditNoteIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors.comments && (
              <span style={{ color: "red" }}>{errors.comments}</span>
            )}
          </div>
        </div>
        <div style={{ ...columnStyle, flex: "2 2 45%", marginTop: "1rem" }}>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            <label className="label-type">Risk</label>
            <TextField
              onBlur={handleBlur}
              id="risk"
              placeholder="Add risk..."
              value={risks}
              onChange={(e) => setRisks(e.target.value)}
              InputProps={{
                sx: commentPropStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditNoteIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors.risk && <span style={{ color: "red" }}>{errors.risk}</span>}
          </div>
        </div>

        {/* Action Buttons */}
        <Box sx={actionButtonWrapperStyle}>
          <button
            style={saveButtonStyle(hasAccess)}
            onClick={hasAccess === "true" ? handleClickOpen : null}
            disabled={hasAccess === "false"}
          >
            Save
          </button>
          <button style={cancelButtonStyle} onClick={() => history.push("/")}>
            Cancel
          </button>
          {id && (
            <button
              onClick={hasAccess === "true" ? handleDeleteClickOpen : null}
              disabled={hasAccess === "false"}
              style={deleteButtonStyle(hasAccess)}
            >
              Delete
            </button>
          )}
        </Box>
      </div>
      <Dialog
        open={saveOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save Initiative?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={createInitative}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "800px",
            margin: "auto",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ paddingLeft: "0px" }}>
          Are you sure you want to delete Initiative ?
        </DialogTitle>
        <label className="label-type-mand">Reason</label>
        <TextField
          value={deleteText}
          placeholder="Please add Reason to delete Initiative"
          onChange={(e) => setDeletetext(e.target.value)}
        />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={deleteInitiative}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </>
  );
}
